<!--
File: Sections.vue
Description: show list of road sections entered in the DB.
-->
<template>
  <div class="md-layout">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
              @input="reloadData" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <DepsDropdown :label="$t('label.select_deu')" v-model="selectedDeu" :initial_value="selectedDeu"
              :region_id="selectedRegion" :disabled="!selectedRegion" @input="refresh" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <BaseDropdown :label="$t('label.select_road')" v-model="selectedRoad" :items="roadsList"
              :displayField="'name'" :valueField="'id'" :disabled="!selectedRegion" @input="refresh" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <md-input type="search" class="mb-3" clearable :placeholder="$t('label.search_records')"
                v-model="searchQuery" :disabled="!selectedRegion" />
            </md-field>
          </div>
          <div class="md-layout-item btn-row md-small-size-100">
            <md-button class="md-success" @click="addSection"> {{ $t('buttons.add') }} </md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
          md-mode="indeterminate" />
        <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <!--md-table-cell v-if="!selectedRegion" style="width:10%" :md-label="$t('road_network.region')" md-sort-by="region">
              {{ item.region }}
            </md-table-cell-->
            <md-table-cell :md-label="$t('road_network.dep')" md-sort-by="deu">
              {{ item.deu }}
            </md-table-cell>
            <md-table-cell :md-label="$t('road_network.road_key')" md-sort-by="road">
              {{ item.road_key }}
            </md-table-cell>
            <md-table-cell style="min-width: 400px;" :md-label="$t('stdCols.name')" md-sort-by="section_description">
              {{ item.section_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('inventory.start_distance_m')" md-sort-by="start_distance_m" md-numeric>
              {{ item.start_distance_m | numFormat(0) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('inventory.end_distance_m')" md-sort-by="end_distance_m" md-numeric>
              {{ item.end_distance_m | numFormat(0) }}
            </md-table-cell>

            <md-table-cell :md-label="$t('tables.actions')">
              <div>
                <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                  @click.native="editSection(item)">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button class="md-just-icon md-success md-simple" :title="$t('buttons.details')"
                  @click="showDetails(item)">
                  <md-icon>preview</md-icon>
                </md-button>
                <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                  @click.stop.prevent="handleDelete(item)">
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions md-alignment="space-between">
        <div class>
          <p class="card-category">
            {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
          </p>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label for="pages">{{ $t('label.per_page') }}</label>
            <md-select v-model="pagination.perPage" name="pages">
              <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                {{ item }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
          :per-page="pagination.perPage" :total="total"></pagination>
      </md-card-actions>
    </md-card>

      <SectionDetails v-if="showDetailsDlg" :sectionId="section.section_id" @close="showDetailsDlg = false" />
  </div>
</template>
<script>
import { Pagination } from '@/components'
import { mapState, mapGetters } from 'vuex'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
import { customSortMixin } from '@/mixins/customSortMixin'
import SectionDetails from './SectionDetails.vue'

export default {
  name: 'sections-list',
  mixins: [customSortMixin],
  data() {
    return {
      selectedRegion: null,
      selectedDeu: null,
      selectedRoad: null,
      showDetailsDlg: false,
      section: {},
      showSpinner: false,
      deuLabel: '',

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['deu', 'road', 'section_description'],
      searchedData: [],
      currentSort: 'deu',
      currentSortOrder: 'asc',
    }
  },
  components: {
    Pagination,
    RegionsDropdown,
    DepsDropdown,
    BaseDropdown,
    SectionDetails
  },
  mounted() {
    this.showSpinner = true
    this.$store.commit('SET_SECTIONS_LIST', [])
    this.deuLabel = this.$t('road_network.dep')

    this.$store.dispatch('LOAD_HISTORY').then((history) => {
      if (history.for === 'sections' && history.use) {
        this.selectedRegion = history.data.selected_region
        this.selectedDeu = history.data.selected_deu
        this.selectedRoad = history.data.selected_road
        this.currentSort = history.data.currentSort
        this.currentSortOrder = history.data.currentSortOrder
        this.pagination.perPage = history.data.perPage
        this.$nextTick(() => {
          this.searchQuery = history.data.searchQuery
          this.pagination.currentPage = history.data.currentPage
        })
        this.$store.dispatch('CLEAR_HISTORY')
        this.reloadData()
      } else {
        this.selectedRegion = null
        this.selectedDeu = null
        this.selectedRoad = null
      }
      this.showSpinner = false
    })
  },

  methods: {
    async reloadData() {
      this.showSpinner = true
      const searchQuery = this.searchQuery
      this.searchQuery = ''
      await this.$store.dispatch('LOAD_SECTIONS_LIST', { region_id: this.selectedRegion })
      if (searchQuery) this.searchQuery = searchQuery
      this.showSpinner = false
    },
    refresh() {
      if (!this.searchQuery) return

      this.showSpinner = true
      const searchQuery = this.searchQuery
      this.searchQuery = ''
      this.$nextTick(() => {
        if (searchQuery) this.searchQuery = searchQuery
      })
      this.showSpinner = false
    },
    save_history() {
      const hist = {
        selectedRegion: this.selectedRegion,
        selectedDeu: this.selectedDeu,
        selectedRoad: this.selectedRoad,
        searchQuery: this.searchQuery,
        currentSort: this.currentSort,
        currentSortOrder: this.currentSortOrder,
        perPage: this.pagination.perPage,
        currentPage: this.pagination.currentPage
      }
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'sections' })
    },
    showDetails(item) {
      this.section = item
      this.showDetailsDlg = true
    },
    editSection(item) {
      this.save_history()
      //console.log(item)
      this.$router.push(`/inventory_data/sections/upd/${item.section_id}`)
    },
    addSection() {
      this.save_history()
      this.$router.push(`/inventory_data/sections/add`)
    },
    handleDelete(item) {
      const alert = {
        title: this.$t('modal.are_you_sure'),
        html: this.$t('modal.you_want_delete', { name: item.section_description }) + '<br><br>' +
          this.$t('modal.the_operation_cannot_be_canceled'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('modal.yes_delete'),
        cancelButtonText: this.$t('buttons.cancel'),
      }
      this.save_history()
      Swal.fire(alert).then((result) => {
        if (result.value) {
          this.$store.dispatch('DEL_SECTION', item.section_id).then(
            () => {
              this.reloadData(this.searchQuery)
              Swal.fire(this.$t('modal.deleted'), this.$t('modal.you_deleted', { name: item.section_description }), 'success')
            },
            (err) => {
              Swal.fire(this.$t('messages.error'), err, 'error')
            }
          )
          // add here deletion of the geometry - may be in the loop?
          //this.$store.dispatch('DEL_SECTION_GEOMETRY', ...)
        }
      })
    },
  },

  computed: {
    ...mapState({
      //   sectionList: (state) => state.Sections.list,
    }),
    ...mapGetters(['amIAdmin', 'sectionsFiltered', 'roadsListFiltered']),
    sectionList() {
      return this.customSort(this.sectionsFiltered(this.deuLabel, this.selectedRegion, this.selectedDeu, this.selectedRoad),
        'start_distance_m')
    },
    roadsList() {
      return this.roadsListFiltered(this.selectedDeu)
    },
    queriedData() {
      const result = !this.searchQuery ? this.sectionList : this.searchedData
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.sectionList.length
    },
    fuseSearch() {
      return new Fuse(this.sectionList, { keys: this.propsToSearch, threshold: 0.1 });
    }
  },

  watch: {
    searchQuery(value) {
      this.searchedData = !this.sectionList.length
        ? []
        : (!value) ? this.sectionList : this.fuseSearch.search(value);
    }
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>